import request from '../../shared/lib/request'

function getWorker(){
    return request({
        url:'/worker/getWorker',
        method:'GET'
    })
}

function getMasters(startOf, endOf){
    return request({
        url:`/worker/getMasters?startOf=${startOf}&endOf=${endOf}`,
        method:'GET'
    })
}

const addWorker = (body) => {
    return request({
        url: '/worker/addWorker',
        method: 'POST',
        data: body,
    })
}
const editWorker = (body) => {
    return request({
        url: `/worker/editWorker`,
        method: 'POST',
        data: body,
    })
}
const removeWorker = (id) => {
    return request({
        url: '/worker/removeWorker/'+id,
        method: 'POST'
    })
}

const WorkerService = {
    getWorker,
    getMasters,
    addWorker,
    editWorker,
    removeWorker,
}

export default WorkerService;