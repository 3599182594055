import { Button, LoadIndicator, Popover, Popup, RadioGroup, Scheduler, SelectBox } from 'devextreme-react';
import React, { useEffect, useRef, useState } from 'react';
import OperationService from "../../services/api/operation";
import RefService from '../../services/api/ref';
import { Editing, Resource, View } from 'devextreme-react/cjs/scheduler';
import moment from 'moment';
import 'moment/locale/mn';
import { locale, loadMessages } from 'devextreme/localization';
import mn from '../../../node_modules/devextreme/localization/messages/mn.json'
import Toolbar from "devextreme/ui/toolbar"
import notify from 'devextreme/ui/notify';
import { FiMoon, FiSun } from 'react-icons/fi';
import { InputNumber } from 'antd';
import WorkerService from '../../services/api/worker';
import { custom } from 'devextreme/ui/dialog';

export const BathOperation = () => {
    locale('mn');
    moment.locale('mn');
    loadMessages(mn);

    const [current, setCurrent] = useState({
        year: moment().year(),
        week: moment().week(),
        startOf: moment().startOf('week').format('YYYY-MM-DD'),
        endOf: moment().endOf('week').format('YYYY-MM-DD'),
    })

    const [bathGrid, setBathGrid] = useState([]);
    const updaterRef = useRef(new Map());
    const [bathRef, setBathRef] = useState([])
    const refHangerStatus = useRef([])
    const refOperationConfigStatus = useRef([])
    const [popup, setPopup] = useState(false)
    const [detailPerBath, setDetailPerBath] = useState(null)
    const [appointmentData, setAppointmentData] = useState([])
    const [statusUpdates, setStatusUpdates] = useState({
        bath_status_id: null,
        operation_config_status_id: null,
    })
    const [masters, setMasters] = useState([])
    const [visibility, setVisibility] = useState(false)
    const initialMastersRef = useRef(null);
    const test = useRef(null)

    useEffect(() => {
        getBaths();
        getMasters();
    }, [current]);

    useEffect(() => {
        getBathStatus();
        getHangerStatus();
        getOperationConfigStatus();
    }, [])

    const getMasters = async () => {
        try {
            let { data } = await WorkerService.getMasters(current.startOf, current.endOf);
            setMasters(data);
            initialMastersRef.current = data;
        } catch (error) {
            console.error(error);
        }
    }

    const getBaths = async () => {
        try {
            let res = await OperationService.getBathsByWeek(current.year, current.week);
            setBathGrid(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getBathStatus = async () => {
        try {
            let res = await RefService.getBathStatus();
            setBathRef(res.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getHangerStatus = async () => {
        try {
            let res = await RefService.getHangerStatus();
            refHangerStatus.current = res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const getOperationConfigStatus = async () => {
        try {
            let res = await RefService.getOperationConfigStatus();
            refOperationConfigStatus.current = res.data;
        } catch (error) {
            console.error(error);
        }
    }


    const onContentReady = (e) => {
        let toolbarInstance = Toolbar.getInstance(e.element.querySelector(".dx-toolbar"));
        if (toolbarInstance.option("items").length === 2) {
            let items = toolbarInstance.option("items");
            toolbarInstance.option("items", [
                items[0],

            ]);
        }
    }

    const onAppointmentUpdating = ({ newData }) => {
        const startDate = moment(newData.start_date.substring(0, 10));
        const configDate = moment(newData.config_date);
        const dayDiff = startDate.diff(configDate, 'days');
        const compositeKey = `${newData.bath_number}-${newData.week_number}`;
        let obj = {
            week_number: newData.week_number,
            bath_number: newData.bath_number,
            day_id: newData.day_id + dayDiff,
            shift_id: newData.start_date.substring(11) === '08:00:00' ? 1 : 2,
            config_date: startDate.format('YYYY-MM-DD')
        }
        updaterRef.current.set(compositeKey, obj);
    };

    const onAppointmentDetail = async ({ targetedAppointmentData }) => {
        setPopup(true)
        setAppointmentData(targetedAppointmentData)
        try {
            let res = await OperationService.getDetailsPerBath(current.year, current.week, targetedAppointmentData.bath_number);
            setDetailPerBath(res.data)
        } catch (error) {
            console.error(error);
        }
    };

    const hangerStatusHandler = (hangerNo, value) => {
        setDetailPerBath((prev) =>
            prev.map((item) =>
                item.hanger_number === hangerNo
                    ? { ...item, hanger_status_id: value }
                    : item
            )
        );

    }

    const hangerKatodHandler = (hangerNo, value) => {
        setDetailPerBath((prev) =>
            prev.map((item) =>
                item.hanger_number === hangerNo
                    ? { ...item, katod_count: value }
                    : item
            )
        );
    }

    const handlePerBathDetails = async () => {
        const body = {
            statusUpdates,
            hangerUpdates: detailPerBath
        }
        try {
            await OperationService.changeBathDetails(body, current.year, current.week, appointmentData.bath_number)
            notify({ message: 'Амжилттай хадгаллаа', width: 'fit-content' }, "success", 2000);
        } catch (error) {
            console.error(error);
        } finally {
            setPopup(false)
            getBaths()
            setStatusUpdates({
                bath_status_id: null,
                operation_config_status_id: null,
            })
        }
    }

    const statusColor = (id) => {
        switch (id) {
            case 1:
                return '#000';
            case 2:
                return '#ced4df';
            case 3:
                return '#f95f53';
            default:
                return '#ffffff';
        }
    }

    const appointmentRender = ({ appointmentData }) => {
        return (
            <div className='scheduler-bath' style={{ border: `1px solid ${appointmentData.bath_status_id === 3 ? '#f95f53' : '#ced4df'}`, background: `${appointmentData.bath_status_id === 2 && '#f4f5f7'}` }}>
                <b style={{ color: statusColor(appointmentData.bath_status_id) }}>{appointmentData.bath_number}</b>
            </div>
        )
    }

    const changeMaster = async (val) => {
        let myDialog = custom({
            title: "Баталгаажуулалт",
            messageHtml: `<i>Та <b>ээлж шинэчлэхдээ </b> итгэлтэй байна уу?</i>`,
            buttons: [{
                text: "Тийм",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                },
            },
            {
                text: "Үгүй",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                },
            }]
        })
        myDialog.show().then(async (dialogResult) => {
            if (dialogResult.buttonText === "Тийм") {
                initialMastersRef.current = masters;
                try {
                    await OperationService.changeShiftMasters(current.startOf, current.endOf, masters);
                    setVisibility(false);
                    notify({ message: 'Амжилттай хадгаллаа', width: 'fit-content' }, "success", 2000);
                } catch (error) {
                    console.log('error', error)
                }
            }
        })
    }

    const timeCellRender = (e) => {
        return (
            <div onClick={() => setVisibility(true)}>
                {e.text !== '' ?
                    <div className='d-flex flex-col align-item-center'><FiSun size={18} /><b>1-р ээлж</b>08:00-16:00</div>
                    :
                    <div className='d-flex flex-col align-item-center'><FiMoon size={18} /><b>2-р ээлж</b>16:00-00:00 </div>}
            </div>
        )
    }

    const handleCurrentDateChange = (e) => {
        const newDate = moment(e);
        const newYear = newDate.year();
        const newWeek = newDate.week();
        const startOfWeek = newDate.startOf('week').format('YYYY-MM-DD');
        const endOfWeek = newDate.endOf('week').format('YYYY-MM-DD');
        setCurrent({
            year: newYear,
            week: newWeek,
            startOf: startOfWeek,
            endOf: endOfWeek,
        });
    };
    
    const saveChanges = async () => {
        if (updaterRef.current.size > 0) {
            try {
                const body = Array.from(updaterRef.current.values());
                let res = await OperationService.changeShiftDayByWeek(body, current.year)
                res === 'success' && notify({ message: 'Амжилттай хадгаллаа', width: 'fit-content' }, "success", 2000);
            } catch (error) {
                console.error('Batch update failed:', error);
            } finally {
                updaterRef.current.clear();
            }
        }
    }

    return (
        <div className='layout' >
            <Popover
                showTitle
                visible={visibility}
                onHiding={() => JSON.stringify(initialMastersRef.current) !== JSON.stringify(masters) ?
                    notify({ message: 'Та ээлжийн өөрчлөлтөө хадгална уу.', width: 'fit-content' }, "warning", 2000)
                    : setVisibility(false)}
                title='Ээлж тохируулах'
                showCloseButton={true}
                target='.dx-scheduler-time-panel'
                width={450}
                contentRender={() => (
                    <>
                        <div className='d-flex flex-col g-2'>
                            {masters.map((e, index) => (
                                <div className='form-row' key={index}>
                                    <span className='form-field'>{e.shift_id}-р ээлж:</span>
                                    <div className='form-value d-flex'>
                                        <RadioGroup
                                            dataSource={masters}
                                            value={e.shift_master_id}
                                            defaultValue={e.shift_master_id}
                                            layout="horizontal"
                                            displayExpr="full_name"
                                            valueExpr="shift_id"
                                            onValueChanged={(event) => {
                                                setMasters((prev) =>
                                                    prev.map((item) =>
                                                        item.shift_id === e.shift_id
                                                            ? { ...item, shift_master_id: event.value }
                                                            : item
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='d-flex justify-center'>
                            <Button type='normal' text='Хадгалах' className='mt-3' style={{ fontWeight: 600, borderRadius: 20 }} onClick={changeMaster} />
                        </div>
                    </>
                )}
            />
            <Scheduler
                dataSource={bathGrid}
                onCurrentDateChange={handleCurrentDateChange}
                customizeDateNavigatorText={(e) => {
                    return moment(e.startDate).format('ll') + ' → ' + moment(e.endDate).format('ll')
                }}
                dateCellRender={(e) => moment(e.date).format('dddd D')}
                timeZone='Asia/Ulaanbaatar'
                groupByDate={false}
                currentView='workWeek'
                showAllDayPanel={false}
                allDayPanelMode='hidden'
                startDayHour={8}
                endDayHour={24}
                cellDuration={480}
                adaptivityEnabled={true}
                startDateExpr='start_date'
                endDateExpr='end_date'
                shadeUntilCurrentTime={false}
                showCurrentTimeIndicator={true}
                textExpr='bath_number'
                appointmentRender={appointmentRender}
                timeCellRender={timeCellRender}
                onContentReady={onContentReady}
                onAppointmentUpdating={onAppointmentUpdating}
                onAppointmentTooltipShowing={(e) => e.cancel = true}
                onAppointmentClick={onAppointmentDetail}
            >
                <Resource colorExpr='black' />
                <Editing allowDeleting={false} allowTimeZoneEditing={false} allowUpdating={moment().week() <= current.week} allowAdding={false} />
                <View type='workWeek' groupOrientation='vertical' maxAppointmentsPerCell='unlimited' />
            </Scheduler>
            <div className='d-flex justify-space-between' style={{padding: 10, border: '1px solid rgba(222,225,227,.6)', borderTop: 'none', background: '#f5f5f5'}}>
                <Button text='Өөрчлөлт хадгалах' className='changeButton' disabled={moment().week() > current.week} type='normal' style={{ fontWeight: 600, borderRadius: 20 }} onClick={saveChanges} />
                <div className="d-flex g-3 align-item-center">
                    Ванны төлөв: {bathRef.map((e) => (
                        <div className='d-flex g-2 align-item-center'>
                            <span className='badge' style={{ backgroundColor: statusColor(e.id) }}></span>
                            {e.name}
                        </div>
                    ))}
                </div>
            </div>
            <Popup
                visible={popup}
                onHiding={() => { setPopup(false); setDetailPerBath(null); setAppointmentData([]) }}
                showTitle={true}
                width={500}
                height={500}
                dragEnabled={true}
            >
                <div className='appointment-container' style={{ display: 'flex', flexDirection: 'column', gap: 14 }}>
                    <div className='form-row'>
                        <span className='form-field'>Ванны дугаар:</span>
                        <div className='form-value' style={{ fontWeight: 'bold' }}>№{appointmentData.bath_number}</div>
                    </div>
                    <div className='form-row'>
                        <span className='form-field'>Ванны төлөв:</span>
                        <div className='form-value' style={{ fontWeight: 'bold' }}>
                            <SelectBox items={bathRef} displayExpr={'name'} valueExpr={'id'} placeholder={appointmentData?.bath_status}
                                onValueChanged={(e) => {
                                    setStatusUpdates(prevState => ({ ...prevState, bath_status_id: e.value }));
                                    setDetailPerBath(prev => prev.map(item => ({ ...item, hanger_status_id: e.value === 1 ? 1 : 2 })))
                                }} />
                        </div>
                    </div>
                    <div className='form-row'>
                        <span className='form-field'>Ажиллагааны төлөв:</span>
                        <div className='form-value' style={{ fontWeight: 'bold' }}>
                            <SelectBox items={refOperationConfigStatus.current} displayExpr={'name'} valueExpr={'id'} placeholder={appointmentData?.operation_status}
                                onValueChanged={(e) => setStatusUpdates(prevState => ({ ...prevState, operation_config_status_id: e.value }))} />
                        </div>
                    </div>
                    <hr />
                    {detailPerBath ?
                        <>
                            <p style={{ textAlign: 'end' }}>Катодын тоо:</p>
                            {detailPerBath.map((e, i) =>
                                <div className='form-row' id='hanger_loader'>
                                    <span className='col-3'>{e.hanger_number}-р өлгүүр:</span>
                                    <div className='col-8' style={{ fontWeight: 'bold' }}>
                                        <RadioGroup
                                            dataSource={refHangerStatus.current}
                                            layout="horizontal"
                                            displayExpr='name'
                                            defaultValue={e.hanger_status_id}
                                            value={e.hanger_status_id}
                                            valueExpr={'id'}
                                            onValueChanged={(event) => hangerStatusHandler(e.hanger_number, event.value)}
                                        />
                                    </div>
                                    <InputNumber
                                        defaultValue={e.katod_count}
                                        controls={false}
                                        className='col-2'
                                        onChange={(val) => hangerKatodHandler(e.hanger_number, val)}
                                    />
                                </div>
                            )}
                        </>
                        :
                        <LoadIndicator height={20} width={20} />
                    }
                    <Button
                        text='Хадгалах' style={{ position: 'absolute', bottom: 20, left: 20, right: 20, borderRadius: 20 }}
                        useSubmitBehavior={true}
                        onClick={handlePerBathDetails}
                        className='custom-save'
                    />
                </div>
            </Popup>
        </div>
    );
};
