import { ConfigProvider, Select } from 'antd'
import { DateBox, SelectBox } from 'devextreme-react'
import moment from 'moment'
import Chart, { CommonSeriesSettings, Crosshair, Label, Legend, Tooltip, Series, ValueAxis, ArgumentAxis, Point, Font, Strip, Format } from 'devextreme-react/chart'
import React, { useEffect, useState } from 'react'
import CopperService from '../../services/api/copper'
import LoadPanelComponent from '../../components/loadPanel'
import { numberWithCommas } from '../../util'

const Report = () => {
    const [date, setDate] = useState(new Date().getTime())
    const [month, setMonth] = useState(new Date().getMonth())
    const [selectedVal, setSelectedVal] = useState('all')
    const [chartData, setChartData] = useState([])
    const [loading, setLoading] = useState(false)
    const shift = [
        { value: 'all', label: 'Бүгд' },
        { value: 1, label: '1-р ээлж' },
        { value: 2, label: '2-р ээлж' }
    ]
    let months = []
    for (let i = 0; i < 12; i++) {
        months.push({
            value: i + 1,
            label: `${i + 1}-р сар`
        })
    }

    useEffect(() => {
        fetchData()
    }, [date, selectedVal, month])
    const fetchData = async () => {
        try {
            setLoading(true)
            let year = new Date(date).getFullYear()
            const res = await CopperService.getProdChartData(selectedVal, year, month)
            const transformedData = res.data.map(item => {
                const day = new Date(item.ognoo).getDate();
                const formattedDay = day < 10 ? day.toString() : day;
                return { ...item, ognoo: formattedDay };
            });
            setChartData(transformedData)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log('err')
        }
    }
    const handleDate = (e) => {
        setDate(e)
    }
    const handleChange = (value) => {
        setSelectedVal(value)
    };
    let cards = ["Бүтээгдэхүүний төлөвлөгөө /тоо, ш, жин/", "Бүтээгдэхүүний гүйцэтгэл /тоо, ш, жин/", "Экспортын гүйцэтгэл /тоо, ш, жин/", "Экспортын гүйцэтгэл /тоо, ш, жин/"]
    const customizeValueAxisLabel = (e) => {

        return `${(e.value / 1000).toLocaleString(undefined)}.0`
    }
    const customizeValueAxisLabelRight = (e) => {
        return `${e.value}%`
    }
    function markerTemplate(item) {
        const color = item.series.isVisible() ? item.marker.fill : '#888';
        return (
            <svg> 
                <rect x={0} y={0} width={12} height={12} fill={color}
                    rx={item.text.includes('Хувь') ? 6 : 0}
                    ry={item.text.includes('Хувь') ? 6 : 0}></rect>
            </svg>
        );
    }

    const onLegendClick = ({ target: series }) => {
        if (series.isVisible()) {
            series.hide()
        } else {
            series.show()
        }
    }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Төлөвлөгөө' || arg.seriesName === 'Гүйцэтгэл') {
            let index = chartData.findIndex((e)=>parseInt(arg.argument) === parseInt(e.ognoo))
            let prev = chartData[index - 1];
            let q 
            let p
            let text
            if(arg.seriesName === 'Төлөвлөгөө'){
                text = `Өнөөдрийн /${arg.argumentText}/ төлөвлөгөө:`
                q = ((arg.value - (prev && prev?.plan) > 0 ? arg.value / (prev && prev?.plan) : (prev && prev?.plan) / arg.value)).toFixed(2); 
                p = ((arg.value * 100 / (prev && prev?.plan)) - 100).toFixed(2);
            } else {
                text = `Өнөөдрийн /${arg.argumentText}/ гүйцэтгэл:`
                q = ((arg.value - (prev && prev?.copper_weight) > 0 ? arg.value / (prev && prev?.copper_weight) : (prev && prev?.copper_weight) / arg.value)).toFixed(2); 
                p = ((arg.value * 100 / (prev && prev?.copper_weight)) - 100).toFixed(2);
            }
            let d = (arg.value - (prev && arg.seriesName === 'Төлөвлөгөө' ? prev?.plan : prev?.copper_weight));
            let f = d < 0 ? 'бага' : 'их';
            
            if (isFinite(q) && !isNaN(q) && prev !== undefined) {
                return {
                    html: `<div class="tooltip-header">
                    <span>${text}</span>
                    <span>${numberWithCommas(arg.value)}кг</span>
                    </div>
                    <hr/>
                    <b><i>Өмнөх өдрөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas(d)}кг<br/>
                    <span class="tooltip-series-name">Хувь: </span> ${p}%<br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span>${d === 0 ? '0' :  `${q} дахин ${f}`} <br/>
                    `
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>${text}:</span>
                    <span>${numberWithCommas(arg.value)}</span>
                    </div>`
                }
            }
        } else {
            const index = chartData.findIndex((e)=>parseInt(arg.argument) === parseInt(e.ognoo))
            const prev = chartData[index - 1];
            if(prev){
                return {
                    html: `<div class="tooltip-header">
                        <span>Өнөөдрийн /${arg.argumentText}/ гүйцэтгэлийн хувь:</span>
                        <span>${numberWithCommas(arg.value)}%</span>
                        </div>
                        <hr/>
                        <b><i>Өмнөх өдрөөс:</i></b><br/>
                        <span class="tooltip-series-name">Зөрүү:</span> ${(prev?.percent - arg?.value).toFixed(2)}%<br/>
                    `
                };
            } else{
                return {
                    html: `<div class="tooltip-header">
                        <span>Өнөөдрийн /${arg.argumentText}/ гүйцэтгэлийн хувь:</span>
                        <span>${numberWithCommas(arg.value)}%</span>
                        </div>`
                }
            }
        }
    }

    return (
        <div id='report'>
            <LoadPanelComponent visible={loading} position={'#report'} />
            <ConfigProvider
                theme={{
                    components: {
                        Select: {
                            controlHeight: 40
                        }
                    },
                    token: {
                        borderRadius: 20,
                        height: 40,
                        colorBorder: '#FFF'
                    }
                }}
            >
                <div className='customizeDateBox m-2 d-flex justify-space-between align-items-center'>

                    <div className='d-flex g-2' >
                        <DateBox
                            width={160}
                            height={40}
                            value={date}
                            // defaultValue={date}
                            type='date'
                            stylingMode='outlined'
                            displayFormat='yyyy'
                            onValueChanged={(e) => {
                                handleDate(e.value)
                            }}
                            calendarOptions={{
                                zoomLevel: "decade",
                                maxZoomLevel: 'decade',
                                minZoomLevel: 'decade'
                            }}
                            style={{ borderRadius: 20, fontSize: 14, border: 'none', paddingLeft: 15 }}
                        />
                        <Select
                            defaultValue={'all'}
                            style={{ width: 120, borderRadius: 20 }}
                            onChange={handleChange}
                            options={shift}
                        />

                    </div>

                </div>
                <div className='card' style={{ padding: '10px 20px', border: 'none' }}>
                    <div className=' d-flex justify-space-between' >
                        {/* data bhgu uchir random too awaw */}
                        {cards.map((e, i) => {
                            return <div key={i} className='card d-flex flex-col justify-space-between align-item-start' style={{ padding: 20, width: 'calc(100%/4 - 5px)' }}>
                                <span style={{ fontSize: 13 }}>{e}</span>
                                <span style={{ fontSize: 28, fontWeight: 'bold' }}>{numberWithCommas(Math.floor(Math.random() * (130000 - 100000 + 1)) + 100000).slice(0,7)} ш</span>
                                <span style={{ fontSize: 28, fontWeight: 'bold' }}>{(Math.floor(Math.random() * (4000 - 3000 + 1)) + 3000)} тн</span>
                                <span style={{ fontSize: 13 }}>Сүүлд шинэчлэгдсэн огноо: 2024-09-09</span>
                            </div>
                        })}

                    </div>
                    
                    <div className='card' style={{ padding: 10 }}>
                        {chartData && chartData.length> 0 ?
                        <div>
 <div className='d-flex justify-space-between align-items-center' style={{ marginBottom: 10 }}>
                            <h5>1. Төлөвлөгөө, гүйцэтгэл /өдрөөр/</h5>
                            <SelectBox
                                dataSource={months}
                                value={month}
                                width={200}
                                onValueChange={(e) => setMonth(e)}
                                label='Сар сонгох'
                                valueExpr={'value'}
                                displayExpr={'label'}
                            />
                        </div>

                        <Chart
                            dataSource={chartData}
                            height={'80%'}
                            onLegendClick={onLegendClick}
                        >
                            <CommonSeriesSettings
                                argumentField="budget_month"
                                type="bar"
                                // barOverlapGroup="myStack"
                                barWidth={20}
                                cornerRadius={2}
                            >
                                <Label visible={false} />

                            </CommonSeriesSettings>
                            <Tooltip zIndex={100000} enabled={true} cornerRadius={6} border={{ visible: false }}
                                customizeTooltip={customizeTooltip} font={{ color: '#fff' }} color="#555" location="edge">
                            </Tooltip>
                            <ValueAxis>
                                <Label customizeText={customizeValueAxisLabel} />
                            </ValueAxis>
                            <ValueAxis name="total"
                                position="right"
                            >
                                <Label customizeText={customizeValueAxisLabelRight} />
                            </ValueAxis>
                            <Legend
                                visible={true}
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                                font={{ family: "Segoe UI" }}
                                markerRender={markerTemplate}
                            />
                            <Series valueField='plan' argumentField='ognoo' name='Төлөвлөгөө' color={'#F4B084'} />
                            <Series valueField='copper_weight' argumentField='ognoo' name='Гүйцэтгэл' color={'#404040'}/>
                            <Series
                                axis="total"
                                type="spline"
                                argumentField='ognoo'
                                valueField="percent"
                                name="Хувь"
                                color="#ED7D31"
                                bor
                            />
                        </Chart>
                        </div>
                        :
                        <span>Мэдээлэл байхгүй</span>
                    }
                       
                    </div>
                </div>
            </ConfigProvider>

        </div>

    )
}

export default Report